<template>
  <div
    class="collectible-card-small position-relative py-2 d-inline-flex flex-column align-items-center"
    :class="{ 'reorder-handle': isReorderable, 'image-only': imageOnly }"
    :style="{
      background: accentStyle.background,
      backgroundColor: accentStyle.backgroundColor,
      color: accentStyle.color,
    }"
  >
    <ion-button
      v-if="!isReorderable && isRemovable"
      class="position-absolute remove-selection-btn"
      fill="clear"
      @click="remove"
      ><i class="ti-close"
    /></ion-button>
    <ion-checkbox
      v-if="isSelectable && !imageOnly"
      class="select position-absolute mt-2 ml-n1"
      mode="ios"
      :checked="isSelected"
      :disabled="true"
    />
    <div class="clickable h-100 w-100" @click="toggleSelection">
      <div v-if="!imageOnly" class="flex-grow-1 top-section" :style="{ backgroundColor: accentStyle.backgroundColor }">
        <p class="name">{{ truncate(collectible.name, { length: 14 }) }}</p>
      </div>

      <div class="image">
        <img
          loading="lazy"
          v-image
          class="collectible-image"
          :src="get(collectible, 'image', '/empty.png')"
          @click="click"
        />
      </div>

      <div
        v-if="!imageOnly"
        class="flex-grow-1 d-flex flex-column justify-content-center desc g-0"
        :style="{ backgroundColor: accentStyle.backgroundColor }"
      >
        <span class="d-block bold source">{{ collectible.source || 'Source' }}</span>
        <span
          v-if="collectible.count >= 10"
          class="item-count m-0"
          :style="{ backgroundColor: accentStyle.backgroundColor }"
        >
          {{ collectible.count }}
        </span>
        <span class="btm-desc" v-else-if="collectible.short_description">{{ collectible.short_description }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { toast } from '@/shared/native/toast';
import { Collectible } from '@/shared/types/static-types';
const props = defineProps({
  isRemovable: {
    type: Boolean,
  },
  isReorderable: {
    type: Boolean,
  },
  imageOnly: {
    type: Boolean,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  isSelectable: {
    type: Boolean,
    default: false,
  },
  isSelected: {
    type: Boolean,
    default: false,
  },
  collectible: {
    type: Object,
    default: () => ({}),
  },
  selectedCount: {
    type: Number,
    default: 0,
  },
});

const emits = defineEmits(['select', 'click']);

const collectible = computed(() => props.collectible as Collectible);
const isReorderable = computed(() => props.isReorderable as boolean);
const accentStyle = ref<any>({});

function toggleSelection() {
  if (!props.isSelected && props.selectedCount >= 5) {
    toast.show('You can only feature up to 5 collectibles.', 'nonative', 'danger');
  } else {
    const data = {
      detail: {
        checked: !props.isSelected,
      },
    };
    select(data);
  }
}

const isSelected = toRef(props, 'isSelected');

const remove = (e: any) => {
  e.preventDefault();

  const data = {
    detail: {
      checked: !isSelected.value,
    },
  } as CustomEvent;

  select(data);
};

function select(e: any) {
  if (isReorderable.value) return;

  emits('select', e.detail.checked);
}

function click() {
  emits('click');
}

function recalcColors(collectible: Collectible) {
  if (!collectible) return null;
  if (collectible.rarity === 'Amethyst Rare') {
    accentStyle.value = {
      color: 'white',
      background: 'linear-gradient(180deg, rgba(174, 56, 229, 0) -75%, #AE00FF 100%)',
      backgroundColor: 'transparent',
    };
  } else if (collectible.rarity === 'Ultra Rare') {
    accentStyle.value = {
      color: '#FFA500',
      backgroundColor: '#FFA500',
    };
  } else if (collectible.rarity === 'Super Rare') {
    accentStyle.value = {
      color: '#00B4C5',
      backgroundColor: '#00B4C5',
    };
  } else if (collectible.rarity === 'Rare') {
    accentStyle.value = {
      color: '#328FD2',
      backgroundColor: '#328FD2',
    };
  } else {
    accentStyle.value = {};
  }
}

watch(
  collectible,
  (newCollectible) => {
    recalcColors(newCollectible);
  },
  { deep: true }
);

onMounted(() => {
  recalcColors(collectible.value);
});
</script>

<style lang="sass" scoped>
.btm-desc
  overflow: hidden
  text-overflow: ellipsis
  width: 100%
  -webkit-line-clamp: 1
  -webkit-box-orient: vertical
  display: -webkit-box
  color: #FFF !important
.remove-selection-btn
  z-index: 22
  background: rgba(0,0,0,0.5) !important
  pointer-events: all
  --width: 25px
  --height: 25px
  --padding-start: 8px !important
  --padding-end: 8px !important
  border-radius: 20px
  color: #FFF !important
  min-width: none
  right: -10px
  top: -10px
  overflow: hidden
.select
  left: 1rem
.disabled
  pointer-events: none
  *
    pointer-events: none
.actions
  transform: scale(0.8)
  .inline-button
    &:not(:first-of-type)
      margin: 0 5px
.collectible-card-small
  background-color: #340A6D
  border-radius: 12px
  padding: 0 !important
  width: 100%
  height: 150px !important
  min-width: 120px
  max-width: 120px
  ::v-deep
    .checkbox-disabled
      opacity: 1 !important

  *
    font-size: 14px
  .image
    height: 88px
    width: 100%
    img
      width: 100%
      height: 100%
      object-fit: cover
      user-select: none !important
  .name
    margin: 0
    font-size: 12px
    font-weight: bold
  .left-desc
    font-size: 11px
    font-weight: bold
    color: #FFF !important
  .top-section
    width: 100%
    text-align: center
    background-color: #340A6D
    border-radius: 12px 12px 0 0
    color: white
    line-height: 30px
  .desc
    display: block
    color: white
    background-color: #340A6D
    text-align: center
    height: 33px
    width: 100%
    margin: auto 0 0 0
    padding: 0 4px
    border-radius: 0 0 12px 12px
    line-height: 10px
    font-size: 11px
    span
      margin: 0px
      font-size: 10px
    .source
      margin: 0 0 3px 0
      width: 100%
      height: 10px
      overflow: hidden
      text-overflow: ellipsis
      width: 100%
      -webkit-line-clamp: 1
      -webkit-box-orient: vertical
      display: -webkit-box
  .right-desc
    font-size: 11px
    right: 10px
    color: #FFF !important
  .icon-button
    color: #ae38e5
    font-size: 20px
    .d-flex
      font-size: 20px
.primary-color
  color: #7f10b3
.secondary-color
  color: #3dc2ff
.routerlink
  color: #214163
.item-count
  display: inline-block
  background-color: #CD67EF
  border-radius: 5px
  line-height: 16px
  font-size: 12px
  padding: 3px 15px
.image-only
  width: 60px !important
  height: 60px !important
  min-width: unset !important
  border-radius: 6px !important
  img
    width: 60px !important
    height: 60px !important
    border-radius: 6px !important
</style>
