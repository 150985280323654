<template>
  <ion-modal
    class="modal-with-layout"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <modals-layout @dismiss-modal="dismissModal">
      <div v-if="collectible" class="collectible-card-modal container-fluid">
        <div
          class="w-100 collectible-header"
          :style="{ backgroundColor: accentStyle.backgroundColor, color: accentStyle.color }"
        >
          <div
            class="top-bar d-inline-block name"
            :class="[collectible.short_description ? ['col-12', 'col-sm-6'] : ['col-12']]"
          >
            <span>{{ collectible.name }}</span>
          </div>
          <div v-if="collectible.short_description" class="top-bar col-12 col-sm-6 d-inline-block short-desc">
            <span>{{ collectible.short_description }}</span>
          </div>
        </div>

        <div class="row m-0 w-100 d-flex justify-content-center info-container">
          <div
            class="mt-3 col-12 col-sm-6 left-side img-card"
            :style="{ backgroundColor: accentStyle.imageBackgroundColor, background: accentStyle.background }"
          >
            <h2 class="rarity py-1" :style="{ backgroundColor: accentStyle.backgroundColor, color: accentStyle.color }">
              {{ collectible.rarity }}
            </h2>
            <div class="img-container">
              <img
                :src="collectible.image"
                class="clickable-item-hov-custom"
                @click="openVisualPreview(collectible.image)"
              />
            </div>
            <div class="count" :style="{ backgroundColor: accentStyle.backgroundColor, color: accentStyle.color }">
              <span v-if="collectible.count === 1">
                <span v-if="collectible.in_circulation_count === 1" class="bold">Unique</span>
                <span v-else class="bold">1 of {{ collectible.in_circulation_count }}</span>
              </span>
              <span v-else class="bold">{{ collectible.count }}</span>
            </div>
          </div>

          <div class="mt-3 col-12 col-sm-6 d-flex-column right-side">
            <div
              v-if="collectible.description"
              class="long-text right-side-inner"
              :style="{ backgroundColor: accentStyle.lighterBackgroundColor, color: accentStyle.color }"
            >
              {{ collectible.description }}
            </div>
            <h2 :style="{ color: accentStyle.labelColor }">Type</h2>
            <div
              class="right-side-inner"
              :style="{ backgroundColor: accentStyle.lighterBackgroundColor, color: accentStyle.color }"
            >
              {{ collectible.item_type }}
            </div>
            <h2 :style="{ color: accentStyle.labelColor }">Date Obtained</h2>
            <div
              class="right-side-inner"
              :style="{ backgroundColor: accentStyle.lighterBackgroundColor, color: accentStyle.color }"
            >
              {{ receiptDate }}
            </div>
            <h2 v-if="collectible.source" :style="{ color: accentStyle.labelColor }">Obtained From</h2>
            <div
              v-if="collectible.source"
              class="right-side-inner"
              :style="{ backgroundColor: accentStyle.lighterBackgroundColor, color: accentStyle.color }"
            >
              {{ collectible.source }}
            </div>
            <h2 v-if="collectible.perks" :style="{ color: accentStyle.labelColor }">Perks</h2>
            <div
              v-if="collectible.perks"
              class="long-text right-side-inner"
              :style="{ backgroundColor: accentStyle.lighterBackgroundColor, color: accentStyle.color }"
            >
              {{ collectible.perks }}
            </div>
          </div>
        </div>
      </div>
    </modals-layout>
    <VisualPreviewModal
      :image-url="selectedImg"
      :is-open="openVisualPreviewModal"
      @dismiss-modal="closeVisualPreviewModal"
    />
  </ion-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Collectible } from '@/shared/types/static-types';

import { formatDate } from '@/shared/utils/dateTime';
import VisualPreviewModal from '@/shared/modals/VisualPreviewModal.vue';
import { mainStore } from '@/shared/pinia-store/main';

@Options({
  name: 'CollectibleCardModal',
  components: { VisualPreviewModal },
})
export default class CollectibleCardModal extends Vue {
  @Prop({ required: true }) collectible!: Collectible;
  @Prop({ default: () => '', required: false }) modalTitle!: string;
  @Prop({ default: false }) isOpen!: boolean;

  public get dark() {
    const { dark } = mainStore();
    return dark.value;
  }

  public accentStyle: any = {};
  public openVisualPreviewModal = false;
  public selectedImg = '';

  public dismissModal() {
    this.$emit('dismissModal');
  }

  public get receiptDate() {
    if (!this.collectible) return '';
    return formatDate(this.collectible.received_at);
  }

  @Watch('collectible')
  public onCollectibleChange(collectible: Collectible | null) {
    this.setColors(collectible);
    this.setImage(collectible);
  }

  public mounted() {
    this.setColors(this.collectible);
    this.setImage(this.collectible);
  }

  public openVisualPreview(imgUrl: string) {
    this.selectedImg = imgUrl;
    this.openVisualPreviewModal = true;
  }

  public closeVisualPreviewModal() {
    this.openVisualPreviewModal = false;
    this.selectedImg = '';
  }

  public setImage(collectible: Collectible | null) {
    if (!collectible) return;
    this.selectedImg = collectible.image;
  }

  public setColors(collectible: Collectible | null) {
    if (!collectible) return;
    if (collectible.rarity === 'Amethyst Rare') {
      this.accentStyle = {
        color: 'purple',
        labelColor: this.dark ? 'lavender' : 'purple',
        background: 'linear-gradient(180deg, white -50%, #AE00FF 250%)',
        backgroundColor: '#EDD8FF',
        lighterBackgroundColor: '#EDD8FF',
      };
    } else if (collectible.rarity === 'Ultra Rare') {
      this.accentStyle = {
        color: '#C75F00',
        labelColor: '#C75F00',
        backgroundColor: '#FFC354',
        background: '#FFF5E2',
        imageBackgroundColor: '#FFF5E2',
        lighterBackgroundColor: '#FFF5E2',
      };
    } else if (collectible.rarity === 'Super Rare') {
      this.accentStyle = {
        color: 'white',
        labelColor: 'gray',
        backgroundColor: '#00B4C5',
        background: '#DDF1F2',
        imageBackgroundColor: '#DDF1F2',
        lighterBackgroundColor: '#00B4C5',
      };
    } else if (collectible.rarity === 'Rare') {
      this.accentStyle = {
        color: 'white',
        labelColor: 'gray',
        backgroundColor: '#328FD2',
        background: '#E2F3FF',
        imageBackgroundColor: '#E2F3FF',
        lighterBackgroundColor: '#328FD2',
      };
    } else {
      this.accentStyle = {
        color: this.dark ? 'white' : '',
        labelColor: this.dark ? 'white' : '#431C89',
        imageBackgroundColor: 'white',
        background: 'white',
        lighterBackgroundColor: this.dark ? '#431C89' : '',
      };
    }
  }
}
</script>

<style lang="sass" scoped>
.collectible-header
  background-color: #431C89
  color: white
  border-radius: 20px
  height: 50px
  line-height: 50px
  display: block
@media (max-width: 575px)
  .collectible-header
    padding-top: 5px
    height: 75px
    line-height: 30px
.short-desc
  text-align: center
  font-size: 0.8rem
  overflow: hidden
.name
  font-weight: bold
  text-align: center
  vertical-align: top
.rarity
  color: #431C89
  text-align: center
  align-content: start
  margin-top: 0
.count
  background-color: #431C89
  color: white
  text-align: center
  height: 24px
  line-height: 24px
  min-width: 100%
  overflow: hidden
  bottom: 0
.img-container
  margin: auto
  margin-top: 30px
.full-height
  position: relative
  display: inline-block
  height: 400px
  max-height: calc(100% - 55px)
  overflow-y: auto
h2
  color: #431C89
  font-size: 1rem
  font-weight: bold
  margin: 0.4rem 0
.long-text
  white-space: pre-wrap
.left-side
  display: flex
  padding: 0 !important
  flex-direction: column
  border-radius: 20px
  border: solid #eee 0.1px !important
  overflow: hidden
  height: 400px
  img
    max-height: 100%
    max-width: 100%
    margin: 0 auto
    position: relative
    object-fit: contain
.right-side
  padding: 0 0 0 0.5rem
@media (max-width: 575px)
  .right-side
    padding: 0
.right-side-inner
  background-color: #FAF5FF
  padding: 10px
  gap: 10px
  border-radius: 20px
.info-container
  display: block
  max-height: 100%
.collectible-card-modal
  height: 100%
  padding-right: 0.5rem
  overflow-y: auto
.clickable-item-hov-custom
  cursor: pointer
.clickable-item-hov-custom:hover
  opacity: 0.7
.img-card
  border: none !important
</style>
